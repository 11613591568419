import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';

import { Avatar, Button, Card, Col, message, Row } from 'antd';
import { Form, Grid } from 'antd/es';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { countries } from 'countries-list';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { PatchAthleteReqDto } from '../../api/shared/req/patch-athlete-req.dto';
import { UserResDto } from '../../api/shared/res/user-res.dto';
import { successMessage } from '../../components/antd-notifications/antd-notifications';
import FloatInput from '../../components/float-components/float-input';
import FloatSelect from '../../components/float-components/float-select';

export const UserEditPage = function UserEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [athlete, setAthlete] = useState<UserResDto | null>(null);
  const [form] = useForm<PatchAthleteReqDto>();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const sizing = Grid.useBreakpoint();
  const translationPrefix = 'accountPage';

  useEffect(() => {
    const load = async () => {
      if (id) {
        setLoading(true);
        const response = await ServerController.CoachAthlete.get(id);
        if (!IsErrorResDto(response)) {
          const singleUser = response.data[0];
          form.setFieldsValue({
            firstName: singleUser.firstName,
            lastName: singleUser.lastName,
            shippingAddress: singleUser.shippingAddress,
            // to do add image update.
          });
          setAthlete(singleUser);
          setImageUrl(singleUser.base64Image);
        }
      }
      setLoading(false);
    };
    load();
  }, [id, form]);

  const countriesMemo = useMemo(() => {
    const countriesArray: {
      value: string;
      label: string;
    }[] = [];
    let countryKey: keyof typeof countries;
    for (countryKey in countries) {
      countriesArray.push({
        value: countryKey,
        label: countries[countryKey].name,
      });
    }
    return countriesArray;
  }, []);

  const updateUser = async (values: PatchAthleteReqDto) => {
    try {
      setLoading(true);
      if (athlete) {
        const response = await ServerController.CoachAthlete.patch({ ...values, base64Image: imageUrl }, athlete?.id!);
        if (!IsErrorResDto(response)) {
          navigate('/users');
          message.open(successMessage(t('userPage.athleteUpdated')));
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const getBase64 = (img: File, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      message.error('File not uploaded, please try again..');
      return;
    }
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLessThen5M = file.size / 1024 / 1024 < 5;
    if (!isLessThen5M) {
      message.error('Image must smaller than 5MB!');
      return;
    }
    getBase64(file, (url) => {
      setLoading(false);
      setImageUrl(url.replace('data:image/png;base64,', ''));
    });
  };

  return (
    <div className="container">
      <Card title={t('userPage.editUserDetails')} className="rounded shadow-sm" loading={loading}>
        <Row className="mb-2">
          <Col md={2}>
            <label htmlFor="file" style={{ cursor: 'pointer' }}>
              <input type="file" id="file" name="file" onChange={handleFileChange} hidden />
              {!imageUrl ? (
                <Avatar shape="square" size={64} icon={<UserAddOutlined />} />
              ) : (
                <Avatar shape="square" size={64} src={imageUrl}></Avatar>
              )}
            </label>
          </Col>
          <Col md={21}>
            {!imageUrl ? (
              <>
                <div>
                  <b>Edit Image</b>
                </div>
                <div>Image size should be maximin 5 MB.</div>
                <span>File format: jpeg, png.</span>
              </>
            ) : (
              <>
                <label htmlFor="file">
                  <Button type="link" onClick={() => document.getElementById('file')?.click()}>
                    <EditOutlined />
                    {t(`userPage.editImage`)}
                  </Button>
                </label>
                |
                <Button type="link" danger onClick={() => setImageUrl('')}>
                  <DeleteOutlined />
                  {t(`userPage.removeImage`)}
                </Button>
              </>
            )}
          </Col>
        </Row>
        <Form<PatchAthleteReqDto> form={form} onFinish={updateUser} initialValues={athlete!}>
          <FormItem name="email">
            <FloatInput type="email" label={t(`${translationPrefix}.register.email.label`)} readOnly />
          </FormItem>
          <Row className="row-form-item" gutter={12} justify="center">
            {/* <Button shape="round" type="dashed" danger>
              Send Reset Password Link
            </Button> */}
          </Row>
          <Row className="row-form-item" gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.name.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.name.first`)} required />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: t(`${translationPrefix}.register.name.last`) as string }]}
              >
                <FloatInput label={t(`${translationPrefix}.register.name.last`)} required />
              </Form.Item>
            </Col>
          </Row>
          <FormItem
            name={['shippingAddress', 'country']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.register.shippingAddress.country.required`) as string,
              },
            ]}
          >
            <FloatSelect
              options={countriesMemo}
              showSearch
              label={t(`${translationPrefix}.register.shippingAddress.country.label`)}
              filterOption={(input, option) => {
                const regex = new RegExp(input, 'i');
                return regex.test(option!.label as string);
              }}
              required
            />
          </FormItem>
          <Row className="row-form-item" gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name={['shippingAddress', 'postalCode']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.shippingAddress.postalCode.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.shippingAddress.postalCode.label`)} required />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <FormItem
                name={['shippingAddress', 'city']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.shippingAddress.city.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.shippingAddress.city.label`)} required />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name={['shippingAddress', 'address']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.register.shippingAddress.address.required`) as string,
              },
            ]}
          >
            <FloatInput label={t(`${translationPrefix}.register.shippingAddress.address.label`)} required />
          </FormItem>
          <FormItem name={['shippingAddress', 'state']}>
            <FloatInput label={t(`${translationPrefix}.register.shippingAddress.state.label`)} />
          </FormItem>

          <FormItem wrapperCol={sizing.xs === true ? undefined : { offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>
              {t(`save`)}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>
  );
};
